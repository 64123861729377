<style lang="less" scoped></style>

<style lang="less">
  .vertical .org-chart-node-label .org-chart-node-label-inner {
    writing-mode: vertical-lr;
    height: 164px;
  }
  .horizontal {
    .org-chart-node-label {
      .org-chart-node-label-inner {
        width: 164px;
        padding: 3px;
        margin: 2px 0;
      }
    }
  }
  .vertical .org-chart-node-label .org-chart-node-label-inner, .horizontal .org-chart-node-label .org-chart-node-label-inner {
    border-radius: 0;
    overflow: visible;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
  .vertical .org-chart-node {
    padding: 20px 1px 0 1px;
  }
  .org-chart-node-label-inner {
    position: relative;
    .action {
      display: flex;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 175px;
      transform: translate(-50%, -50%);
      transition: all .3s;
    }
    &:hover {
      .action {
        opacity: 1;
      }
    }
  }
  .vertical .org-chart-node-label-inner {
    position: relative;
    .action {
      display: block;
      opacity: 0;
      width: 80px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all .3s;
    }
    &:hover {
      .action {
        opacity: 1;
      }
    }
  }
  .pink-theme {
    @pink-color: #F4628F;
    @default-color: #7F7F7F;
    .org-chart-node-label-inner {
      // background-color: #F4628F;
      color: @default-color;
      border: 1px solid @default-color;
      &:hover {
        .action {
          .fm-btn:hover {
            background-color: #FFF;
          }
        }
      }
    }
    .horizontal {
      .org-chart-node:not(.is-left-child-node):after {
        border-top-color: @default-color;
        border-left-color: @default-color;
      }
      .org-chart-node:not(.is-left-child-node):before {
        border-left-color: @default-color;
      }
      .org-chart-node-children:before {
        border-top-color: @default-color;
      }
      .org-chart-node:not(.is-left-child-node):not(.is-not-child):last-child:before {
        border-bottom-color: @default-color;
      }
    }
    .vertical {
      .org-chart-node:before {
        border-top-color: @default-color;
      }
      .org-chart-node:after {
        border-left-color: @default-color;
        border-top-color: @default-color;
      }
      .org-chart-node:last-child:before {
        border-right-color: @default-color;
      }
      .org-chart-node-children:before {
        border-left-color: @default-color;
      }
      .org-chart-node-label-inner {
        .action {
          .fm-btn {
            writing-mode: initial;
          }
          .fm-btn + .fm-btn {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }
    .horizontal, .vertical {
      .org-chart-node-label {
        .org-chart-node-btn {
          border-color: @default-color;
        }
        .org-chart-node-btn:before {
          border-top-color: @default-color;
        }
        .org-chart-node-btn:after {
          border-left-color: @default-color;
        }
      }
    }
  }
</style>

<template>
  <div class="org-page">
    <div class="org-page-div">
      <div class="org-page-content content-block tree-img">
        <div class="content-top">
          <fm-title title-text="组织机构" :note-text="noteText" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu">
            <div class="is-tree-check" v-if="$authFunsProxy.tree && $authFunsProxy.get">
              <a v-if="$authFunsProxy.tree" :class="{'b-active': isTree}" href="javascript:;" class="b-l" @click="isTree = true">机构组织架构</a>
              <a v-if="$authFunsProxy.get" :class="{'b-active': !isTree}" href="javascript:;" class="b-r" @click="isTree = false">机构设置</a>
            </div>
          </fm-title>
        </div>
        <div class="content-body" v-if="!isTree && $authFunsProxy.get">
          <fm-table-new
            @on-column-config-update="(columns) => $onTableColumnUpdate(columns, 'main')"
            :simple-filter="true"
            :columns="columnList"
            :auto-height="true"
            :toolbox="showToolBox"
            export-file-name="组织机构"
            size="small"
            v-loadingx="loading"
            border="row"
            :data-list="tableDataList"
            :stripe="false"
            emptyPlaceholder="-"
            @counterChange="countDataChange">
            <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
          </fm-table-new>
        </div>
        <div class="content-body content-body-tree" v-if="isTree && $authFunsProxy.tree">
          <div style="width: calc(100vw - 190px);overflow: auto;padding: 50px 0;" v-if="direction !== false">
            <div style="width: 3000px; position: relative;" :style="{transform: direction !== 'horizontal' ? 'scale(0.7) translateX(-20%)' : 'scale(0.7) translateX(-20%) translateY(-20%)'}">
              <vue-okr-tree show-collapsable default-expand-all class="vertical-t" label-height="" label-width="" :data="orgTreeData" :direction="direction" :render-content="renderContent"></vue-okr-tree>
            </div>
          </div>
          <table class="content-body-tb" v-else border="0" cellspacing="0" cellpadding="0">
            <tr class="org-table-th">
              <th class="org-table-cell" rowspan="1" :colspan="maxLevel">机构设置</th>
              <!-- <th class="org-table-cell" rowspan="1" colspan="1">职务</th> -->
            </tr>
            <tr class="org-table-td" v-for="(item, index) in showTreeData" :key="index">
              <td class="org-table-cell" :rowspan="itemd.h" :colspan="itemd.w" v-for="(itemd, indexd) in item" :key="indexd">
                <div class="cell-item" v-if="!Array.isArray(itemd.label)">
                  <a class="cell-item-label" href="javascript:;" @click="chooseKey = chooseKey === 'o' + itemd.data.id ? null : 'o' + itemd.data.id">{{itemd.label}}</a>
                  <!-- <div v-if="itemd.label" class="action" :class="{'action-active': ('o' + itemd.data.id) === chooseKey}">
                    <fm-btn size="mini" @click="orgItemClick(itemd, 'info')" v-if="$authFunsProxy.goWorker">人员</fm-btn>
                    <fm-btn size="mini" @click="orgItemClick(itemd, 'count')" v-if="$authFunsProxy.goCount">统计</fm-btn>
                  </div> -->
                </div>
                <template v-else>
                  <div class="cell-item" v-for="pItem in itemd.label" :key="pItem.id">
                    <a class="cell-item-label" href="javascript:;" @click="chooseKey = chooseKey ===  'p' + pItem.id ? null : 'p' + pItem.id">{{pItem.name}}</a>
                    <!-- <div v-if="itemd.label" class="action" :class="{'action-active': ('p' + pItem.id) === chooseKey}">
                      <fm-btn size="mini" @click="pItemClick(pItem, 'info')" v-if="$authFunsProxy.goWorker">人员</fm-btn>
                      <fm-btn size="mini" @click="pItemClick(pItem, 'count')" v-if="$authFunsProxy.goCount">统计</fm-btn>
                    </div> -->
                  </div>
                </template>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <fm-form-dialog
      form-title="组织机构"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseData"
      label-alone
      :mask-closable="false"
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import TableActions from '@/components/base/TableActions'

import {
  orgRequest
} from '@/api'

import {VueOkrTree} from 'vue-okr-tree';
import 'vue-okr-tree/dist/vue-okr-tree.css'

let maxLevel = 0
let dealTreeData = (treeData) => {
  let datas = []
  maxLevel = 1
  treeData.forEach((v) => {
    getH(v, 1)
  })
  treeData.forEach((v) => {
    getLevel(v, [], datas)
  })
  return {datas, maxLevel}
}

let getH = (data, level) => {
  maxLevel = maxLevel > level ? maxLevel : level
  let h = 0
  if (data.child && data.child.length > 0) {
    data.child.forEach(v => {
      h += getH(v, level + 1)
    })
  } else {
    h = 1
  }
  data.h = h
  data.level = level
  return h
}

let getLevel = (data, show, datas) => {
  let showItem = {
    label: data.name,
    w: data.w,
    h: data.h,
    data: data
  }
  show.push(showItem)
  if (data.child && data.child.length > 0) {
    data.child.forEach((v, index) => {
      getLevel(v, index === 0 ? show : [], datas)
    })
    showItem.w = 1
  } else {
    showItem.w = maxLevel - data.level + 1
    // show.push({
    //   label: data.positions,
    //   w: 1,
    //   h: 1,
    //   data: data
    // })
    datas.push(show)
  }
}

function dealOrgTreeData (datas) {
  return datas.filter(v => v.id).map(v => {
    return {
      label: v.name,
      data: v,
      children: dealOrgTreeData(v.child)
    }
  })
}

export default {
  components: {
    TableActions, VueOkrTree
  },
  data () {
    return {
      isTree: true,
      chooseKey: null,
      loading: false,
      dataList: [],
      noteText: '',
      treeData: [],
      maxLevel: 0,
      openDialog: false,
      chooseData: {},
      direction: false,
      otherPositionList: []
    }
  },
  computed: {
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    showTreeData () {
      return this.otherPositionList && this.otherPositionList.length > 0 ? this.treeData.concat([[{
        w: this.maxLevel,
        h: 1
      },
      {
        w: 1,
        h: 1,
        label: this.otherPositionList.map(v => v.data.name).join(','),
        data: {
          positions: this.otherPositionList.map(v => v.data)
        }
      }]]) : this.treeData
    },
    tableDataList () {
      let data = []
      this.getData(this.dataList, data)
      return data
    },
    formParms: {
      get () {
        let data = [{
          type: 'input',
          label: '名称',
          key: 'name',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '编码',
          key: 'code'
        },
        // {
        //   type: 'input',
        //   label: '类型',
        //   key: 'type'
        // },
        {
          type: 'select',
          label: '父级机构',
          key: 'pId',
          selectDatas: this.$store.getters.orgList
        },
        // {
        //   type: 'select',
        //   label: '类型',
        //   key: 'outputBenefit',
        //   selectDatas: [{key: 1, label: '绩效贡献类'}, {key: 0, label: '维持运行类'}]
        // },
        {
          type: 'input',
          label: '说明',
          key: 'description'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    tableActions () {
      let data = [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data.filter(v => this.$authFunsProxy[v.key])
    },
    baseColumnList: {
      get () {
        let data = [{
          title: '名称',
          fixed: 'left',
          field: 'name',
          sort: true
        },
        {
          title: '编码',
          fixed: 'left',
          field: 'code',
          sort: true
        },
        {
          title: '父级机构',
          field: 'pOrgName',
          sort: true
        },
        // {
        //   title: '类型',
        //   field: 'outputBenefit',
        //   render: (h, rowData) => {
        //     return h('div', rowData && rowData.outputBenefit ? '绩效贡献类' : '维持运行类')
        //   }
        // },
        {
          title: '说明',
          field: 'description',
          sort: true
        },
        {
          title: '操作',
          slot: 'actions',
          fixed: 'right',
          width: 100,
          export: false,
          configurable: false
        }]
        return data
      }
    },
    columnList () {
      return this.$getTableConfig('main', this.baseColumnList)
    },
    titleMenus () {
      if (!this.isTree) {
        return this.$authFunsProxy.add ? [{ key: 'add', label: '新增' }] : []
      } else if (this.$authFunsProxy.tree) {
        if (this.direction !== false) {
          return [
            { key: 'tree', label: '图表切换' },
            { key: 'direction', label: '横纵切换' }
          ]
        } else {
          return [
            { key: 'tree', label: '图表切换' }
          ]
        }
      } else {
        return []
      }
    },
    orgTreeData () {
      return dealOrgTreeData(this.dataList)
    }
  },
  created () {
    this.initData()
    this.loadData()
    this.isTree = this.$authFunsProxy.tree
  },
  methods: {
    async initData () {
      if (this.$authFunsProxy.edit || this.$authFunsProxy.add) {
        await this.$store.dispatch('loadOrgList')
      }
      if (this.$authFunsProxy.tree) {
        await this.$store.dispatch('loadOrgList')
      }
    },
    getData (dataList, data) {
      dataList.filter(v => v.id).forEach((v) => {
        data.push(v)
        if (v.child && v.child.length > 0) {
          this.getData(v.child, data)
        }
      })
    },
    pItemClick (item, type = 'info') {
      let positionIds = [item.id]
      if (type === 'info') {
        this.$router.push({ name: 'worker.worker', query: {positionIds: positionIds.join(',')}})
      } else {
        this.$router.push({ name: 'worker.count', query: {positionIds: positionIds.join(',')}})
      }
      this.chooseKey = null
    },
    orgItemClick (item, type = 'info') {
      let positionIds = []
      this.getPositionIds(item.data, positionIds)
      if (type === 'info') {
        this.$router.push({ name: 'worker.worker', query: {positionIds: positionIds.join(',')}})
      } else {
        this.$router.push({ name: 'worker.count', query: {positionIds: positionIds.join(',')}})
      }
      this.chooseKey = null
    },
    getPositionIds (data, pIds) {
      if (data.positions && data.positions.length > 0) {
        data.positions.filter(v => !pIds.includes(v.id)).forEach(v => pIds.push(v.id))
      }
      if (data.child && data.child.length > 0) {
        data.child.forEach(v => this.getPositionIds(v, pIds))
      }
    },
    renderContent (h, node) {
      return h('div', [
        h('div', node.label)
      ])
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    dealSData (data) {
      data.forEach((v) => {
        if (v.child && v.child.length > 0 && v.positions && v.positions.length > 0) {
          v.child.push({
            name: '',
            positions: v.positions
          })
        }
        if (v.child && v.child.length > 0) {
          this.dealSData(v.child)
        }
      })
    },
    async loadData () {
      try {
        this.loading = true
        if (this.$authFunsProxy.tree || this.$authFunsProxy.get) {
          let data = await orgRequest.getTree()
          this.dataList = data
          if (this.$authFunsProxy.tree) {
            this.dealSData(data)
            let dealData = dealTreeData(data)
            this.treeData = dealData.datas
            this.maxLevel = dealData.maxLevel
          }
        }
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    formSubmit (data, resolve) {
      if (this.chooseData && this.chooseData.id) {
        orgRequest.update(this.chooseData.id, data).then(() => {
          this.$notify({
            title: '系统提示',
            message: '组织机构修改完成',
            type: 'success'
          })
          this.loadData()
          this.$store.dispatch('loadOrgList', true)
          resolve()
          this.openDialog = false
        })
      } else {
        orgRequest.add(data).then(() => {
          this.$notify({
            title: '系统提示',
            message: '组织机构新增完成',
            type: 'success'
          })
          this.loadData()
          this.$store.dispatch('loadOrgList', true)
          resolve()
          this.openDialog = false
        })
      }
    },
    clickTitleMenu (key) {
      this.chooseData = null
      if (key === 'add') {
        this.openDialog = true
      } else if (key ==='tree') {
        this.direction = this.direction === false ? 'horizontal' : false
      } else if (key === 'direction') {
        this.direction = this.direction === 'horizontal' ? 'vertical' : 'horizontal'
      }
    },
    tableAction (data) {
      this.chooseData = data.data
      if (data.action === 'edit') {
        this.openDialog = true
      } else if (data.action === 'del') {
        this.delData()
      }
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除组织机构吗?'})
      if (result) {
        orgRequest.del(this.chooseData.id).then(() => {
          this.$notify({
            title: '系统提示',
            message: '组织机构已删除',
            type: 'info'
          })
          this.loadData()
          this.$store.dispatch('loadOrgList', true)
        })
      }
    }
  }
}
</script>

<style lang="less">
.vertical-t {
  .vertical {
    .org-chart-node {
      .org-chart-node-label-inner {
        height: 22px;
        writing-mode: lr;
      }
      .org-chart-node {
        .org-chart-node-label-inner {
          height: 22px;
          writing-mode: lr;
        }
        .org-chart-node {
          .org-chart-node-label-inner {
            height: 164px;
            writing-mode: vertical-lr;
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="less">
@import '../../styles/values.less';
.b-r {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  padding: 0 24px;
  border: 1px solid rgba(217, 217, 217, 1);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.b-l {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  border: 1px solid rgba(217, 217, 217, 1);
  padding: 0 24px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.b-active {
  border-color: rgba(244, 98, 143, 1);
  color: rgba(251, 109, 143, 1);
}
.is-tree-check {
  height: 100%;
  display: flex;
  align-items: center;
}
.org-table-th {
  background: rgba(250, 250, 250, 1);
  height: 32px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
}
.org-table-td {
  height: 32px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
}
.content-body-tb {
  border-left: 1px solid #dcdee2;
  border-top: 1px solid #dcdee2;
  margin-left: 25px;
  margin-top: 10px;
  margin-bottom: 25px;
}
.org-table-cell {
  border-right: 1px solid #dcdee2;
  padding: 0 25px;
  border-bottom: 1px solid #dcdee2;
  .cell-item {
    position: relative;
    min-height: 32px;
    display: flex;
    align-items: center;
    .cell-item-label {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 400;
    }
    .action {
      position: absolute;
      top: 26px;
      left: 0;
      display: none;
      opacity: 1;
      z-index: 2;
      background-color: #FFF;
      transition: all .3s;
    }
    .action-active {
      display: flex;
      opacity: 1;
    }
  }
}
.content-bottom {
  height: 4.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.org-page-div {
  border-radius: 8px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.org-page-div-no-padding {
  margin: 0;
}
.org-page {
  width: 100%;
  height: 100%;
  display: flex;
  .fm-table-head-cells {
    background-color: #FFF;
  }
}
.org-page-top-1 {
  margin-bottom: 1rem;
}
.org-page-top-2 {
  margin-bottom: 1.25rem;
}
.org-page-content {
  flex: 1;
  height: 100%;
}
.content-body-tree {
  overflow: auto;
  position: relative;
}
.tree-img {
  background-image: url(/static/images/work/1.png);
  background-size: 339px;
  background-position: bottom right;
  background-repeat: no-repeat;
}
.content-body {
  flex: 1;
  position: relative;
}
</style>
